import React, { useEffect } from "react";
import { Box, Typography, useColorScheme, useTheme } from "@mui/joy";
import Logo from "../../Logo";

export interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  const { mode, setMode } = useColorScheme();
  const { palette } = useTheme();

  useEffect(() => {
    if (mode !== "dark") {
      setMode("dark");
    }
  }, [mode, setMode]);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: palette.background.body,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Logo
          style={{
            height: "72px",
          }}
        />
        <Typography level='h1'>BuildForm</Typography>
        <Typography>Coming soon...</Typography>
      </Box>
    </Box>
  );
};

export default Home;
