import React from "react";
import ReactDOM from "react-dom/client";
import AppThemeProvider from "./Providers/AppThemeProvider";

import "@fontsource-variable/montserrat";
import Home from "./Scenes/Home";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <Home />
    </AppThemeProvider>
  </React.StrictMode>
);
